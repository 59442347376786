<template>
  <div
    class="examinationpaper clearfix"
    :class="{
      'examinationpaper--isTongueType': isTongueType,
    }"
  >
    <template v-if="pageTypeIsErrorCreated">
      <zv-nav-bar :title="pagename" v-if="!isShow"></zv-nav-bar>
    </template>
    <template v-else-if="pageTypeIsPractice">
      <zv-nav-bar :title="pagename" :clickLeft="save" v-if="!isShow"></zv-nav-bar>
    </template>
    <!-- <template v-else-if="paperType !== '25'">
      <zv-nav-bar :title="pagename" v-if="!isShow" :clickLeft="save"></zv-nav-bar>
    </template> -->
    <template v-else-if="pageTypeIsSimulativeExam">
      <van-nav-bar title="模拟考试" v-if="!isShow" :clickLeft="goBack"></van-nav-bar>
    </template>
    <van-nav-bar title="正式考试" :clickLeft="goBack" v-else></van-nav-bar>
    <div
      v-if="!isShow"
      ref="paper-box"
      class="paperbox"
      :class="{
        'paperbox--tongue': isTongueType && pageTypeIsPractice,
        'paperbox--tongue-exam': isTongueType && !pageTypeIsPractice,
        'paperbox--scroll':
          isTongueType && tongueMediaSrcData.videoAssertID && (tongueStatus.textExpended || isRecording),
        'paperbox--colloquial': isTongueType,
      }"
    >
      <div
        class="video-box"
        v-if="questionlist[activeIndex] && isTongueType && pageTypeIsPractice"
        ref="video"
        :class="{ 'video-box--unclickable': isRecording }"
      >
        <ali-player
          ref="ali-player"
          :videoSrc="tongueMediaSrcData.videoUrl"
          :playVideoCallback="initCurrentAudio"
          :videoCover="questionlist[activeIndex].videoCover"
          v-if="tongueMediaSrcData.videoUrl"
          v-show="!hideVideoPlayer"
        />
      </div>

      <div
        class="tongue-box"
        :class="{
          'tongue-box--isTongueType': isTongueType,
        }"
        v-if="questionlist[activeIndex] && isTongueType"
      >
        <template v-for="(tongueItem, t_index) in questionlist">
          <div class="tongue-topic" :key="tongueItem.ID" v-show="activeIndex === t_index">
            <div class="title__wrap" :class="{ 'title__wrap--single-line': !pageTypeIsPractice }">
              <div class="spoken-title">
                <span class="title_type">{{ tongueItem.typeName }}</span>
                <span class="title_text" v-html="tongueItem.title"></span>
              </div>
              <div class="current-score" v-if="pageTypeIsPractice">
                <img
                  :src="
                    require(`/public/images/allCourse/answer/${isLatestRecordPassed ? 'pic_qualified' : 'icon_no'}.png`)
                  "
                />
                上次得分：
                <span :class="{ passed: isLatestRecordPassed }">{{
                  recordingAudioList[0] && recordingAudioList[0].synthesize
                }}</span>
              </div>
              <div class="operate-group" v-if="pageTypeIsPractice">
                <span class="pass-condition">本题需要训练合格{{ tonguePracticeNum }}次</span>
                <!-- <span class="pass-condition" v-if="pageTypeIsOfficialExam || pageTypeIsSimulativeExam">本题{{ tongueItem.mark }}分</span> -->
                <!-- <img class="read-title-btn" @click="handleReadTitle" :src="require(`/public/images/allCourse/paper/handle-${mapReadTitleIcon.get(tongueBtnStatus.isReadingTitle)}-btn.png`)" /> -->
              </div>
            </div>
            <!-- 练习 -->
            <div class="main__wrap" v-if="Number(paperType) === 1 && isShowContent === 1">
              <div class="read-text">
                <div class="title">准确朗读以下标准内容</div>
                <!-- <span :ref="'read-text' + tongueItem.ID" class="text" :class="{ 'text-overflow': tongueStatus.textExpended }">{{ tongueItem.answer }}</span> -->
                <div class="text-scroll__wrap" style="overflow-y: auto">
                  <span
                    :ref="'read-text' + tongueItem.ID"
                    class="text"
                    :class="{ 'text-overflow': !tongueStatus.textExpended && showMoreBtn }"
                    v-html="handleTalkAnswer(tongueItem.answer || '')"
                  >
                  </span>
                  <span v-if="showMoreBtn" class="more-btn" @click="handleExpandText">
                    {{
                      tongueStatus.textExpended
                        ? tongueMediaSrcData.videoAssertID
                          ? '[观看视频]'
                          : '[收起]'
                        : '[更多]'
                    }}
                  </span>
                  <!-- <div class="placeholder" style="width: 100%; height: 20vw; background-color: #f8f8f8" v-show="isRecording"></div> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="testbox test-box--recording"
        :class="{
          'testbox--openViewParsing': openViewParsing,
        }"
        v-else
      >
        <template v-for="(q, q_index) in questionlist">
          <template v-if="q_index === activeIndex">
            <template>
              <div class="title" :key="q.ID" style="padding: 0 0.33rem">
                <!-- <div class="title" :style="{'margin-bottom': (q.type === 'GroupOption' || q.type === 'GroupStem' || q.type === 'CaseAnalysis') ? '0.44rem' : '0.88rem'}" :key="q.ID"> -->
                <span class="title_type">{{ q.typeName }}</span>
                <span class="title_text" v-html="q.title"></span>
              </div>
              <div class="options" :key="q.ID + 'options'" style="padding: 0 0.33rem">
                <template v-if="q.type.indexOf('MultipleChoice') > -1">
                  <van-checkbox-group v-model="q.q_res">
                    <van-checkbox
                      shape="square"
                      :name="item.split('.')[0]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      ><span v-html="item"></span
                    ></van-checkbox>
                  </van-checkbox-group>
                </template>
                <template v-if="q.type.indexOf('SingleChoice') > -1">
                  <van-radio-group v-model="q.q_res">
                    <van-radio
                      :name="item.split('.')[0]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      ><span v-html="item"></span
                    ></van-radio>
                  </van-radio-group>
                </template>
                <template v-if="q.type === 'GroupOption'">
                  <div class="title_p_title" v-html="q.PTitle"></div>
                  <van-radio-group v-model="q.q_res" class="groupOption">
                    <van-radio
                      :name="item.split('.')[0]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      ><span v-html="item"></span
                    ></van-radio>
                  </van-radio-group>
                </template>
                <template v-if="q.type === 'GroupStem'">
                  <div class="title_p_title" v-html="q.PTitle"></div>
                  <van-radio-group v-model="q.q_res">
                    <van-radio
                      :name="item.split('.')[0]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      ><span v-html="item"></span
                    ></van-radio>
                  </van-radio-group>
                </template>
                <template v-if="q.type === 'CaseAnalysis'">
                  <div class="title_p_title" v-html="q.PTitle"></div>
                  <van-checkbox-group v-model="q.q_res">
                    <van-checkbox
                      shape="square"
                      :name="item.split('.')[0]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      ><span v-html="item"></span
                    ></van-checkbox>
                  </van-checkbox-group>
                </template>
                <template v-if="q.type === 'Checking'">
                  <van-radio-group v-model="q.q_res">
                    <van-radio
                      :name="item.split('.')[1]"
                      v-for="(item, index) in q.options.split('_ZVING_')"
                      :key="index"
                      >{{ item.split('.')[2] }}</van-radio
                    >
                  </van-radio-group>
                </template>
                <template v-if="q.type.indexOf('Completion') > -1">
                  <van-field
                    class="tkt"
                    v-for="(item, index) in q.inputOptions"
                    :key="index"
                    v-model="item.value"
                    :placeholder="`请输入第${formatValue(index)}项答案`"
                  />
                </template>
                <template v-if="q.type.indexOf('Answer') > -1">
                  <van-field
                    class="jdt"
                    v-for="(item, index) in q.inputOptions"
                    type="textarea"
                    rows="6"
                    :key="index"
                    v-model="item.value"
                    :placeholder="`请输入答案`"
                  />
                </template>
                <div
                  class="correct"
                  v-show="questionlist[activeIndex] && openViewParsing"
                  style="padding-bottom: 0.2rem"
                >
                  <div style="display: flex; align-items: center">
                    <span class="line"></span>
                    <span class="correcttext"
                      >正确答案:<span style="margin-left: 0.1rem">{{ questionlist[activeIndex].answer }}</span></span
                    >
                  </div>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>

      <div class="changebtn" :class="{ 'change-btn--tongue-practice': isTongueType && pageTypeIsPractice }">
        <div class="operate-btn-group" v-show="showTongueOperateBtnGroup">
          <template>
            <div
              v-if="pageTypeIsOfficialExam && !showSubmitAudioBtn"
              class="sup-btn placeholder"
              :class="{ 'hide-placeholder': pageTypeIsOfficialExam }"
            >
              <img style="max-width: 1.44rem" :src="require('/public/images/allCourse/paper/submit-audio.png')" />
              <div>占位</div>
            </div>
            <div
              class="sup-btn"
              :class="{ 'template-btn--disabled': !this.tongueMediaSrcData.audioUrl }"
              v-if="showListenTemplateAudio"
              @click="playingTemplateAnswerAudio"
            >
              <img
                style="max-width: 1.44rem"
                :class="{ 'animation-paused': !isPlayingTemplateAudio, 'rotate-playing-audio-btn': templateBtnReset }"
                :src="require('/public/images/allCourse/answer/btn_listen.png')"
                ref="rotate-btn"
              />
              <div>听模板</div>
            </div>
            <div class="sup-btn" v-if="showSubmitAudioBtn" @click="submitExamRecordingAudio">
              <img style="max-width: 1.44rem" :src="require('/public/images/allCourse/paper/submit-audio.png')" />
              <div>提交</div>
            </div>
          </template>
          <div class="main-btn" :class="{ 'disabled-click': disabledRecordingBtn }" @click="startRecording">
            <div class="main-btn-bottom">
              <!-- 非img长按不会弹框 -->
              <div class="icon-recording"></div>
              <!-- 合格次数大于0显示 -->
              <span
                class="main-btn-count"
                v-if="pageTypeIsPractice && recordingAudioList.filter((item) => Number(item.isPass)).length > 0"
                >{{ passedAudioCount }}/{{ tonguePracticeNum }}</span
              >
            </div>
            <div>录音</div>
          </div>
          <div class="sup-btn" @click="tongueStatus.showRecordingList = true">
            <img style="max-width: 1.44rem" :src="require('/public/images/allCourse/answer/btn_sound.png')" />
            <div>回放</div>
          </div>
        </div>
        <div class="btn-group">
          <van-button
            class="info-btn"
            plain
            round
            hairline
            size="large"
            type="info"
            :disabled="disabledRecordingBtn"
            @click="isTongueType ? handleSwitchTopicWhenUncommitted('prev') : preQues()"
            >上一题</van-button
          >
          <van-button
            :disabled="!openViewParsing && !isAnswered"
            :color="isAnswered ? '#FE714B' : '#999999'"
            circle
            plain
            v-if="isAllowViewParsing == 1"
            @click="openViewParsing = true"
            style="border-radius: 50%; height: 50px; width: 50px; white-space: nowrap"
            >解析</van-button
          >
          <van-button
            class="info-btn"
            round
            hairline
            size="large"
            type="info"
            :disabled="disabledRecordingBtn"
            @click="isTongueType ? handleSwitchTopicWhenUncommitted('next') : nextQues()"
            v-if="activeIndex !== questionlist.length - 1"
            >下一题</van-button
          >
          <van-button
            class="info-btn"
            round
            hairline
            size="large"
            type="info"
            @click="
              isTongueType
                ? handleSwitchTopicWhenUncommitted('over')
                : getover(pageTypeIsOfficialExam || pageTypeIsSimulativeExam)
            "
            v-else
            :loading="loading"
            >{{ pageTypeIsPractice ? '完成练习' : '完成考试' }}</van-button
          >
        </div>
      </div>
      <div
        class="analysis"
        :class="{
          'analysis--isTongueType': isTongueType,
        }"
        v-show="openViewParsing"
      >
        <van-row class="analysisbtom">
          <div class="correct">
            <div style="display: flex; align-items: center">
              <span class="line"></span>
              <span class="correcttext">答案分析:</span>
            </div>
          </div>
          <div
            class="btomtext"
            v-if="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
            v-html="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
          ></div>
          <div class="btomtext" v-else style="text-align: center">暂无分析</div>
        </van-row>
      </div>
      <div class="boxbotm">
        <span class="botmicon" @click="isTongueType ? handleSwitchTopicWhenUncommitted('list') : golist()"
          ><van-icon name="apps-o"
        /></span>
        <span>{{ activeIndex + 1 + '/' + questionlist.length }}</span>
        <span class="time" v-if="[25, 26].includes(Number(paperType))">倒计时：{{ formatDuring() }}</span>
      </div>
    </div>
    <examination-list
      :questionlist="questionlist"
      type="answer"
      @back="isShow = false"
      v-if="isShow"
      @setActiveIndex="setActiveIndex"
    ></examination-list>
    <video id="video" style="object-fit: fill; display: none" />
    <canvas id="canvas" width="320" height="380" style="display: none" />
    <van-dialog v-model="photoPreviewShow" title="考试抓拍图片预览效果" width="80%" @confirm="confirm">
      <video id="photoPreview" style="width: 100%; height: 100%; object-fit: fill"></video>
    </van-dialog>
    <!-- 录音中 -->
    <van-popup
      position="bottom"
      :value="isRecording"
      :close-on-click-overlay="false"
      @close="handleRecordPopupClose"
      duration="0.2"
      :overlay="false"
      :lock-scroll="false"
      round
    >
      <div class="popup__wrap">
        <img
          style="max-width: 1.5333rem"
          :src="require('/public/images/allCourse/paper/record-over-btn.png')"
          @click="endRecording"
        />
        <div class="btn-text">点击结束</div>
        <!-- <img class="audio-wave" :src="require('/public/images/allCourse/paper/audio-wave.png')" /> -->
        <img class="audio-wave" :src="require('/public/images/allCourse/paper/bowen.gif')" />
        <van-count-down
          ref="recording-countdown"
          class="recording-countdown"
          @finish="endRecording"
          millisecond
          format="mm:ss:SS"
          :auto-start="false"
          :time="formatRecordingTime"
        />
        <!-- <van-count-down ref="recording-countdown" class="recording-countdown" @finish="endRecording" millisecond format="mm:ss:SS" :auto-start="false" :time="305 * 1000" /> -->
      </div>
    </van-popup>
    <!-- 回放音频列表 -->
    <van-popup
      position="bottom"
      :value="tongueStatus.showRecordingList"
      :close-on-click-overlay="false"
      :overlay-style="{ background: 'rgba(0, 0, 0, 0.4)' }"
      round
    >
      <div class="popup-audio-list__wrap">
        <div class="title">
          <span>回放（{{ recordingAudioList.length }}条）</span>
          <span class="close-btn" @click="tongueStatus.showRecordingList = false">关闭</span>
        </div>
        <van-empty image="error" description="暂无录音" v-if="recordingAudioList <= 0" />
        <div class="list-scroll__wrap" v-else>
          <van-list>
            <van-cell
              v-for="(recItem, index) in recordingAudioList"
              :key="index"
              @click="playTongueAudioListItem(recItem)"
            >
              <div
                class="list-item"
                :class="{
                  'list-item--failed': Number(recItem.isPass) === 0 && pageTypeIsPractice,
                  'list-item--playing': recItem.ID === currentAudioID,
                }"
              >
                <div class="col-left">
                  <img
                    v-if="pageTypeIsPractice"
                    class="icon"
                    :src="
                      require(`/public/images/allCourse/answer/${
                        Number(recItem.isPass) === 0 ? 'icon_no' : 'pic_qualified'
                      }.png`)
                    "
                  />
                  <span class="name">{{ `口语${pageTypeIsPractice ? '训练' : '考试'}` + recItem.addTime }}</span>
                  <span class="time">
                    <van-count-down
                      :ref="'item-countdown' + recItem.ID"
                      v-if="Number(recItem.isComplete) === 1"
                      format="mm:ss"
                      :auto-start="false"
                      :time="formatMMss(recItem.lengthHMS)"
                    ></van-count-down>
                    <span v-else>计算中...</span>
                  </span>
                </div>
                <div class="col-right score">
                  <template v-if="pageTypeIsPractice">
                    <span v-if="recItem.ID !== currentAudioID">{{ recItem.synthesize }}</span>
                    <img
                      style="max-width: 15px"
                      :src="require('/public/images/allCourse/paper/playing-gif.gif')"
                      v-else
                    />
                  </template>
                  <template v-else>
                    <img
                      v-if="recItem.ID === currentAudioID"
                      style="max-width: 15px"
                      :src="require('/public/images/allCourse/paper/playing-gif.gif')"
                    />
                  </template>
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div class="close-btn__wrap"></div>
      </div>
    </van-popup>
    <div class="confirm-dialog__wrap" v-if="tongueStatus.showConfirmSubmitRecordingAudioDialog">
      <div class="mask"></div>
      <div class="confirm-dialog">
        <div class="title">确认提交本次录音吗？</div>
        <div class="text">确认提交后进入下一题，将不能更改本次录音评分。</div>
        <div class="confirm-operate-btn-group">
          <van-button
            class="btn btn-cancel"
            type="default"
            @click="tongueStatus.showConfirmSubmitRecordingAudioDialog = false"
            >取消</van-button
          >
          <van-button class="btn btn-highlighted" type="default" @click="submitExamRecordingAudio">提交</van-button>
        </div>
      </div>
    </div>
    <ise-recorder
      ref="iseRecorder"
      :key="dynamicKey"
      :appId="processEnv.VUE_APP_TONGUE_ID"
      :appSecret="processEnv.VUE_APP_TONGUE_SECRET"
      :appKey="processEnv.VUE_APP_TONGUE_KEY"
      :text="questionlist[activeIndex] && clearOutsideHashes(questionlist[activeIndex].answer)"
      :check_type="complexity"
      @text-change="getTongueGrade"
      @get-sid="getSID"
      @recording="handleRecordingStart"
      @record-end="handleRecordingEnd"
      style="width: 0px"
      @hook:mounted="refTick++"
      @getLog="getLog"
      @getError="getError"
      @getWarn="getWarn"
    />
    <!-- @timeOut="handleRecordingTimeout" -->
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { Popup } from 'vant'
import ExaminationList from './ExaminationList.vue'
import Aliplayer from './AliPlayer.vue'
import IseRecorder from '../../components/IseRecorder/IseRecorder.vue'
export default {
  name: 'examinationpaper',
  components: {
    'examination-list': ExaminationList,
    'ali-player': Aliplayer,
    'van-popup': Popup,
    'ise-recorder': IseRecorder,
  },
  data() {
    this.tongueQuestionTypeList = ['Tongue', 'Tongue1', 'Tongue2', 'Tongue3', 'Tongue4', 'Tongue5']
    return {
      processEnv: process.env,
      refTick: 0,
      activeIndex: 0,
      pagename: '课堂练习',
      tongueBtnStatus: {
        isReadingTitle: false,
      },
      tongueStatus: {
        isCommit: false,
        textExpended: false,
        showRecordingList: false,
        showConfirmSubmitRecordingAudioDialog: false,
        nextStep: '',
      },
      myPlayer: null,
      questionlist: [],
      isShow: false,
      remainingTime: 0,
      totalTime: '',
      userAnswer: [],
      count: 0,
      paperID: '',
      key_success: '',
      photoCount: '',
      userImage: '',
      photoPreviewShow: false,
      photoVideoOk: true,
      isPhoto: false,
      loading: false,
      paperType: '',
      complexity: '', // 口语难易度
      saveLoading: false, //是否交卷,
      showMoreBtn: false,
      tonguePracticeNum: 0, // 口语练习 合格次数
      tongueMediaSrcData: {
        audioUrl: '',
        videoAssertID: '',
        videoUrl: '',
      },
      dynamicKey: 0,
      recordingAudioList: [], // 录音列表
      pcmAudioFile: null, // 当前录音文件
      pcmAudioGrade: {},
      currentAudio: '',
      currentUserTongueAudio: '',
      currentAudioID: '',
      isPlayingCurrentAudio: false, // 是否在播放录音
      isPlayingTemplateAudio: false, // 是否播放模板录音
      unconfirmedRecordingAudioID: '',
      disabledRecordingBtn: false,
      handoutID: '', // 如果是从讲义进来的，则有
      templateBtnReset: true,
      limitShortestAudioTimer: null,
      hideVideoPlayer: false,
      tongueSID: '',
      isShowContent: 1,
      overTimer: null,
      examPaperId: null,
      canClosePage: 1,
      //是否展示查看解析
      isAllowViewParsing: 0,
      openViewParsing: false,
      isPracticeSelfTest: false,
      isQrCodeEntry: false,
    }
  },
  computed: {
    // 最近一次练习的口语录音是否通过
    isLatestRecordPassed() {
      return this.recordingAudioList[0] && Number(this.recordingAudioList[0].isPass)
    },
    // 是否显示听模板按钮
    showListenTemplateAudio() {
      return this.pageTypeIsSimulativeExam || this.pageTypeIsPractice
    },
    // 是否显示提交录音按钮
    showSubmitAudioBtn() {
      return (this.pageTypeIsOfficialExam || this.pageTypeIsSimulativeExam) && this.unconfirmedRecordingAudioID
    },
    showTongueOperateBtnGroup() {
      return this.isTongueType && !this.isRecording
    },
    // 正式考试
    pageTypeIsOfficialExam() {
      return Number(this.paperType) === 25
    },
    // 练习
    pageTypeIsPractice() {
      return Number(this.paperType) === 1
    },
    // 错题重组
    pageTypeIsErrorCreated() {
      return Number(this.paperType) === 111
    },
    // 模拟考
    pageTypeIsSimulativeExam() {
      return Number(this.paperType) === 26 || this.isPracticeSelfTest
    },
    // 是否正处于录音中
    isRecording() {
      this.refTick
      return this.$refs.iseRecorder?.getIsRecording()
    },
    // 当前题目是否为口语题
    isTongueType() {
      return this.questionlist[this.activeIndex] && this.questionlist[this.activeIndex].type.indexOf('Tongue') > -1
    },
    // 录音倒计时（ms）
    formatRecordingTime() {
      let time = this.questionlist[this.activeIndex] && this.questionlist[this.activeIndex].recordingTime
      if (!time) return
      return time * 1000 // ms
    },
    passedAudioCount() {
      return this.recordingAudioList.filter((recItem) => Number(recItem.isPass)).length
    },
    isAnswered() {
      if (!this.questionlist[this.activeIndex]) return false
      //录音
      if (this.isTongueType && this.checkAudioCount(true)) {
        return true
      } else if (this.isTongueType && !this.checkAudioCount(true)) {
        return false
      }
      //多选题 单选题
      const isAnsweredMultipleChoice = !!(
        (this.questionlist[this.activeIndex].q_res &&
          Array.isArray(this.questionlist[this.activeIndex].q_res) &&
          this.questionlist[this.activeIndex].q_res.length) ||
        (!Array.isArray(this.questionlist[this.activeIndex].q_res) && this.questionlist[this.activeIndex].q_res)
      )
      //填空 简答题
      let isAnsweredFillBlanks = false
      if (this.questionlist[this.activeIndex].inputOptions && this.questionlist[this.activeIndex].inputOptions.length) {
        this.questionlist[this.activeIndex].inputOptions.forEach(({ value }) => {
          if (value) {
            isAnsweredFillBlanks = true
          }
        })
      }
      return isAnsweredMultipleChoice || isAnsweredFillBlanks
    },
  },
  async created() {
    this.handoutID = this.$route.query.handoutID
    this.key_success = this.$route.query.key_success
    this.pagename = this.$route.query.pagename || '课堂练习'
    this.isQrCodeEntry = this.$route.query.qrCode === 'true'
    await this.getData()

    if (
      localStorage['answer_activeIndex' + this.answerID] &&
      localStorage['answer_activeIndex' + this.answerID] !== '-1' &&
      !this.$route.query.pagename
    ) {
      this.activeIndex = Number(localStorage['answer_activeIndex' + this.answerID]) || 0
    }
  },
  mounted() {
    this.setShare()
    this.examPaperId = this.$route.query.id
    window.addEventListener('pagehide', () => {
      this.goBack()
      clearInterval(this.overTimer)
      this.overTimer = null
    })
  },
  watch: {
    activeIndex: {
      handler(newVal) {
        this.openViewParsing = false
        const currentQuestion = this.questionlist[newVal]
        if (!currentQuestion) return
        // init
        this.dynamicKey = 0
        if (this.isTongueType && !this.pageTypeIsOfficialExam) {
          this.initCurrentAudio()
          this.isPlayingTemplateAudio = false
          this.templateBtnReset = false
          window.setTimeout(() => {
            this.templateBtnReset = true
          }, 1)
        }

        if (this.isTongueType) {
          this.isReadTextOverflowEllipsis()
        }
        this.recordingAudioList = []
        this.tongueMediaSrcData = this.$options.data().tongueMediaSrcData
        if (this.isTongueType) {
          this.tongueMediaSrcData.audioUrl = currentQuestion.audioUrl
          if (currentQuestion.videoAssertID) this.tongueMediaSrcData.videoAssertID = currentQuestion.videoAssertID
        }
        if (this.pageTypeIsPractice) {
          // 口语答题记录
          this.getTongueAudioList(currentQuestion.ID)
        } else if (this.pageTypeIsSimulativeExam || this.pageTypeIsOfficialExam || this.isPracticeSelfTest) {
          // 考试口语答题记录
          this.tongueStatus.isCommit = false
          this.getTongueExamAudioList(currentQuestion.ID)
        }
      },
    },
    'tongueMediaSrcData.videoAssertID': {
      handler(newVal) {
        if (newVal && newVal !== 'null') {
          // 请求视频资源
          this.getTongueVideoSrcByID(newVal)
        }
      },
      immediate: true,
    },
    questionlist: {
      handler(newVal) {
        if (newVal.length <= 0) return
        if (this.isTongueType) {
          if (this.pageTypeIsPractice) {
            this.$nextTick(() => {
              this.isReadTextOverflowEllipsis()
            })
          }
        }
      },
      immediate: true,
    },
    recordingAudioList: {
      handler(newVal) {
        // if (newVal.length <= 0) return
        if (!this.pageTypeIsPractice || this.isPracticeSelfTest) {
          if (newVal.length <= 0) {
            this.unconfirmedRecordingAudioID = ''
            this.tongueStatus.isCommit = false
          } else {
            this.tongueStatus.isCommit = newVal.find((qItem) => Number(qItem.isCommit)) === undefined ? false : true
            this.unconfirmedRecordingAudioID = this.tongueStatus.isCommit ? '' : newVal[newVal.length - 1].ID
          }
        }
      },
      immediate: true,
      deep: true,
    },
    pcmAudioGrade: {
      async handler(newVal) {
        if (JSON.stringify(newVal) === '{}') return
        await this.uploadTongue()
      },
      deep: true,
    },
    isRecording(newVal) {
      if (newVal) {
        this.tongueStatus.textExpended = true
        this.initCurrentAudio()
      } else {
        this.$refs['recording-countdown'] && this.$refs['recording-countdown'].reset()
      }
      if (this.questionlist[this.activeIndex] && this.isTongueType && this.pageTypeIsPractice) {
        this.hideVideoPlayer = newVal
      }
    },
    'tongueStatus.showRecordingList'(newVal) {
      if (this.currentAudioID) {
        this.$refs[`item-countdown${this.currentAudioID}`] &&
          this.$refs[`item-countdown${this.currentAudioID}`][0].reset()
      }
      this.initCurrentAudio()
      if (newVal) {
        this.pauseVideo()
        if (this.pageTypeIsPractice) this.getTongueAudioList(this.questionlist[this.activeIndex].ID)
        else this.getTongueExamAudioList(this.questionlist[this.activeIndex].ID)
      }
      if (!newVal && this.currentAudioID) {
        const ref = this.$refs['item-countdown' + this.currentAudioID]
        ref && ref[0].reset()
        if (this.currentUserTongueAudio) {
          this.currentUserTongueAudio.pause()
          this.currentUserTongueAudio = null
          this.currentAudioID = ''
        }
      }
    },
  },
  methods: {
    sendErrorLog(message) {
      this.$axios.get('course/front/permit/test/tongue/logs', {
        params: {
          message,
        },
      })
    },
    getSID(sid) {
      this.tongueSID = sid || 'cannot get SID'
    },
    getLog(arg) {
      console.log('Log code, msg: ', arg)
      if (arg.code) {
        this.sendErrorLog(`code: ${arg.code}, ${arg.message}`)
        this.$Toast.fail(`code: ${arg.code}, ${arg.message}`)
      }
      if (arg.code == '10114') {
        this.handleRecordingTimeout(arg.message)
      }
    },
    getError(arg) {
      console.log('Err code, msg: ', arg)
      this.$axios.get('course/front/permit/test/tongue/logs', {
        params: {
          message: arg.readyState,
        },
      })
      this.$Toast.fail(`readyState: ${arg.readyState}, ${arg.message}, ${arg.recorderState}`)
    },
    getWarn(warnMsg) {
      console.log('Warn msg: ', warnMsg)
      this.$Toast.fail(warnMsg)
    },
    // getInfo(code) {
    //   console.log('Info code, msg: ', code)
    //   if (code) {
    //     this.$Toast.fail(`code: ${code}`)
    //   }
    // },
    async handleRecordingTimeout(message) {
      console.log('message: ', message)
      await this.$refs.iseRecorder.stopRecord()
      this.dynamicKey++
      this.$Toast.clear()
      this.$Toast.fail('录音超时，请重新录制')
    },
    // 获取考试口语答题记录 考试传key_success 查看答案传examID和paperID
    getTongueExamAudioList(questionID) {
      this.disabledRecordingBtn = true
      this.$axios
        .get(`/tk/front/tongue/answer/${questionID}/exam/details?key_success=${this.key_success}`)
        .then(({ data }) => {
          if (Number(data.status) !== 1) return
          this.recordingAudioList = data.data
        })
        .finally(() => {
          this.disabledRecordingBtn = false
        })
    },
    // 获取口语答题记录
    getTongueAudioList(questionID) {
      this.disabledRecordingBtn = true
      this.$axios
        .get(`/tk/front/tongue/answer/${questionID}/details`, {
          params: {
            paperID: this.id,
            key_success: this.key_success,
          },
        })
        .then(({ data }) => {
          if (Number(data.status) !== 1) return
          this.recordingAudioList = data.data
        })
        .finally(() => {
          this.disabledRecordingBtn = false
        })
    },
    // 保存口语答题记录
    saveTongueAudioList(data) {
      let formData = new FormData()
      // formData.append('file', this.pcmAudioFile)
      formData.append('fluency_score', this.pcmAudioGrade.fluency_score)
      formData.append('integrity_score', this.pcmAudioGrade.integrity_score)
      formData.append('phone_score', this.pcmAudioGrade.phone_score)
      formData.append('tone_score', this.pcmAudioGrade.tone_score)
      formData.append('uuidFileName', data.uuidFileName)
      formData.append('path', data.path)
      formData.append('time', data.time)
      formData.append('size', data.size)
      formData.append('requestID', this.tongueSID)
      const currentID = this.questionlist[this.activeIndex]
      this.$axios
        .post(`/tk/front/tongue/answer/${currentID.ID}/save?key_success=${this.key_success}`, formData)
        .then(({ data }) => {
          if (Number(data.status !== 1)) return
          if (this.pageTypeIsPractice) {
            this.getTongueAudioList(currentID.ID)
            // 及格的录音 分数放入q_res
            if (!Array.isArray(currentID.q_res)) currentID.q_res = []
            if (!Number(data.data.isPass)) {
              currentID.q_res.push(0)
            } else {
              currentID.q_res.push(data.data.synthesize)
            }
            this.save(true, true)
          } else {
            if (!Number(data.data.isPass)) {
              currentID.q_res = 0
            } else {
              currentID.q_res = data.data.synthesize
            }
            this.getTongueExamAudioList(currentID.ID)
            this.unconfirmedRecordingAudioID = data.data.ID
          }
          this.$refs['recording-countdown'].reset()
        })
        .finally(() => {
          this.$Toast.clear()
          this.tongueSID = ''
          this.dynamicKey++
        })
    },
    // 请求口语题的视频资源
    getTongueVideoSrcByID(ID) {
      this.$axios.get(`/tk/front/tongue/answer/assertplayurl?assertID=${ID}`).then(({ data }) => {
        if (Number(data.status) !== 1) {
          // return this.$Toast.fail('视频资源请求失败，请重试')
          this.tongueMediaSrcData.videoUrl = ''
          return
        }
        this.tongueMediaSrcData.videoUrl = data.message
      })
    },
    pauseVideo() {
      // 暂停视频
      const elAliPlayer = this.$refs['ali-player']
      if (elAliPlayer && elAliPlayer.aliPlayer) {
        elAliPlayer.aliPlayer.pause()
      }
    },
    // 开始录音
    async startRecording() {
      console.log('startRecording')
      if (this.recordingAudioList.length >= 20) {
        this.$Toast.fail('可录音次数已达上限')
        return
      }
      let passedCount = 0
      for (let i = 0; i < this.recordingAudioList.length; i++) {
        if (Number(this.recordingAudioList[i].isPass)) {
          passedCount++
        }
      }
      if (passedCount >= this.tonguePracticeNum) {
        this.$Toast.fail('本题已合格，无需再作答')
        return
      }
      if (Number(this.questionlist[this.activeIndex].recordingTime) <= 0) {
        this.$Toast.fail('录音时长不足')
        return
      }
      if (this.pageTypeIsPractice && !this.isPracticeSelfTest) {
        if (this.recordingAudioList.map((recItem) => recItem.isPass).length > this.tonguePracticeNum) {
          this.$Toast.fail('本题已合格，无需再次作答')
        }
        if (this.recordingAudioList.length >= 20) {
          // TODO 上限暂时写死
          this.$Toast.fail('录音次数已达上限')
        }
        this.pauseVideo()
        this.$Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '录音连接中...',
        })
        // this.saveAudioOver = false
        await this.$refs.iseRecorder.startRecord()
        this.$Toast.clear()
        this.limitShortestAudioTimer = window.setTimeout(() => {
          clearTimeout(this.limitShortestAudioTimer)
          this.limitShortestAudioTimer = null
        }, 1200) // 录音连接弹窗有动画延迟，多加200ms
      } else {
        if (this.tongueStatus.isCommit) {
          this.$Toast.fail('只能提交一次答案')
          return
        }
        this.$Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '录音连接中...',
        })
        await this.$refs.iseRecorder.startRecord()
        this.$Toast.clear()
      }
    },
    // 停止录音
    async endRecording() {
      console.log('endRecording')
      if (this.limitShortestAudioTimer) {
        return this.$Toast.fail('至少需要录音1s')
      }
      this.$Toast.loading({
        duration: 0,
        message: '正在生成录音...',
        forbidClick: true,
      })
      this.$refs['recording-countdown'].pause()
      await this.$refs.iseRecorder.stopRecord()
    },
    // 展开文本
    handleExpandText() {
      this.tongueStatus.textExpended = !this.tongueStatus.textExpended
      if (!this.tongueMediaSrcData.videoAssertID) return
      const videoHeight = this.$refs['video'].clientHeight
      if (this.tongueStatus.textExpended) {
        let finished_time = 250
        let interval_time = 10
        let currScrollTop = 0
        let scrollTop = videoHeight || 224
        let top_pixel = scrollTop / (finished_time / interval_time)
        let timer = setInterval(() => {
          currScrollTop += top_pixel
          this.$refs['paper-box'].scrollTop = currScrollTop
          if (currScrollTop >= scrollTop) {
            clearInterval(timer)
          }
        }, interval_time)
      } else {
        this.$refs['paper-box'].scrollTop = 0

        // let finished_time = 200
        // let interval_time = 10
        // let currScrollTop = videoHeight || 224
        // let top_pixel = currScrollTop / (finished_time / interval_time)
        // let timer = setInterval(() => {
        //   currScrollTop -= top_pixel
        //   console.log('currScrollTop: ', currScrollTop)
        //   this.$refs['paper-box'].scrollTop = currScrollTop
        //   if (currScrollTop <= 0) {
        //     clearInterval(timer)
        //   }
        // }, interval_time)
      }
    },
    // 朗读题目
    // handleReadTitle() {
    //   this.tongueBtnStatus.isReadingTitle = !this.tongueBtnStatus.isReadingTitle
    // },
    // 播放模板教学录音
    playingTemplateAnswerAudio() {
      if (!this.tongueMediaSrcData.audioUrl) {
        this.$Toast.fail('暂无音频资源')
        return
      }
      if (!this.isPlayingTemplateAudio) {
        if (!this.currentAudio) this.currentAudio = new Audio(this.tongueMediaSrcData.audioUrl)
        this.currentAudio.loop = true
        this.currentAudio.play()
        this.isPlayingTemplateAudio = true
        this.pauseVideo()
        return
      }
      this.currentAudio.pause()
      this.isPlayingTemplateAudio = false
    },
    // 播放口语回放列表里的录音
    playTongueAudioListItem(recItem) {
      if (this.pageTypeIsPractice && Number(recItem.isPass) === 0) {
        this.$Toast.fail('未通过的录音不允许回放')
        return
      }
      let ref = this.$refs[`item-countdown${recItem.ID}`]

      // 当前没有播放中的音频
      if (!this.isPlayingCurrentAudio) {
        this.currentUserTongueAudio = new Audio(recItem.playURL)
        this.currentUserTongueAudio.onended = function () {
          this.currentAudioID = ''
          this.currentUserTongueAudio.onended = null
          this.initCurrentAudio()
          ref && ref[0].reset()
        }.bind(this)
        this.currentUserTongueAudio.play()
        ref && ref[0].start()
        this.currentAudioID = recItem.ID
        this.isPlayingCurrentAudio = true
        return
      }
      // 暂停
      if (this.currentAudioID === recItem.ID) {
        ref && ref[0].reset()
        this.initCurrentAudio()
        this.currentAudioID = ''
      } else {
        this.$refs[`item-countdown${this.currentAudioID}`] &&
          this.$refs[`item-countdown${this.currentAudioID}`][0].reset()
        this.currentUserTongueAudio.pause()
        this.currentUserTongueAudio = new Audio(recItem.playURL)
        this.currentUserTongueAudio.onended = function () {
          this.currentAudioID = ''
          this.currentUserTongueAudio.onended = null
          this.initCurrentAudio()
          ref && ref[0].reset()
        }.bind(this)
        this.currentUserTongueAudio.play()
        ref && ref[0].start()
        this.currentAudioID = recItem.ID
      }
    },
    handleRecordingStart() {
      // 启动倒计时
      this.$nextTick(() => {
        this.$refs['recording-countdown'].start()
      })
    },
    checkAudioCount(notmsg) {
      if (!this.isTongueType) return true
      if (this.isTongueType && !this.pageTypeIsPractice) return true
      // 口语练习 录音次数是否大于要求合格次数 小于上限（默认20）
      if (this.recordingAudioList.length < this.tonguePracticeNum) {
        if (!notmsg) {
          this.$Toast.fail(`本题至少需要录音${this.tonguePracticeNum}次`)
        }
        return false
      }
      return true
    },
    handleSwitchTopicWhenUncommitted(operate) {
      if (this.pageTypeIsPractice || !this.unconfirmedRecordingAudioID) {
        switch (operate) {
          case 'list':
            this.golist()
            break
          case 'prev':
            this.preQues()
            break
          case 'next':
            this.nextQues()
            break
          case 'over':
            this.getover(this.pageTypeIsOfficialExam || this.pageTypeIsSimulativeExam)
            break
        }
        return
      }
      this.tongueStatus.showConfirmSubmitRecordingAudioDialog = true
      this.tongueStatus.nextStep = operate
    },
    // 获取口语录音文件流
    async handleRecordingEnd(audioData) {
      if (!audioData) return
      const buffer = Buffer.from(audioData, 'base64')
      const base64 = `data:audio/adpcm;base64,${buffer.toString('base64')}`
      const temp = base642Blob(base64)
      this.pcmAudioFile = await new File([temp], 'test.pcm', { type: 'audio/adpcm' })
      function base642Blob(urlData, type) {
        const arr = urlData.split(',')
        const matches = arr[0].match(/:(.*?);/)
        const mime = (matches && matches.length > 1 ? matches[1] : type) || type
        const bytes = window.atob(arr[1])
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i)
        }
        return new Blob([ab], {
          type: mime,
        })
      }
      // let aTag = document.createElement('a')
      // aTag.download = this.pcmAudioFile.name
      // let href = URL.createObjectURL(this.pcmAudioFile) //获取url
      // aTag.href = href
      // aTag.click()
      // URL.revokeObjectURL(href) //释放url
    },

    // 获取口语评分
    getTongueGrade(grade) {
      if (grade) {
        const inner = grade.xml_result.read_chapter.rec_paper.read_chapter
        this.$set(this.pcmAudioGrade, 'fluency_score', Math.floor(inner.fluency_score))
        this.$set(this.pcmAudioGrade, 'integrity_score', Math.floor(inner.integrity_score))
        this.$set(this.pcmAudioGrade, 'phone_score', Math.floor(inner.phone_score))
        this.$set(this.pcmAudioGrade, 'tone_score', Math.floor(inner.tone_score))
      }
    },
    initCurrentAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause()
        this.currentAudio = null
        this.isPlayingTemplateAudio = false
      } else if (this.currentUserTongueAudio) {
        this.currentUserTongueAudio.pause()
        this.currentUserTongueAudio = null
        this.isPlayingCurrentAudio = false
        this.currentAudioID = ''
      }
    },
    // 关闭录音中的popup
    handleRecordPopupClose() {
      console.log('handleRecordPopupClose')
      this.$refs.iseRecorder.stopRecord()
    },
    // 提交考试录音
    submitExamRecordingAudio() {
      this.$Toast.loading({
        duration: 0,
        message: '正在确认录音...',
      })
      // 二次确认request
      this.$axios
        .post(`/tk/front/tongue/answer/${this.unconfirmedRecordingAudioID}/commit?key_success=${this.key_success}`)
        .then(({ data }) => {
          this.$Toast.clear()
          if (Number(data.status) !== 1) {
            this.$Toast.fail('提交失败，请重试')
            return
          }
          this.unconfirmedRecordingAudioID = ''
          this.$Toast.success('提交成功')
          if (this.pageTypeIsPractice) this.getTongueAudioList(this.questionlist[this.activeIndex].ID)
          else this.getTongueExamAudioList(this.questionlist[this.activeIndex].ID)
        })
      this.tongueStatus.showConfirmSubmitRecordingAudioDialog = false
      if (this.tongueStatus.nextStep) {
        switch (this.tongueStatus.nextStep) {
          case 'list':
            this.golist()
            break
          case 'prev':
            this.preQues()
            break
          case 'next':
            this.nextQues()
            break
          case 'over':
            this.getover(this.pageTypeIsOfficialExam || this.pageTypeIsSimulativeExam)
            break
        }
        this.tongueStatus.nextStep = ''
      }
    },
    // 上传录音
    async uploadTongue() {
      this.$Toast.loading({
        duration: 0,
        message: '录音上传中...',
        forbidClick: true,
      })
      let formData = new FormData()
      formData.append('file', this.pcmAudioFile)
      if (!this.pageTypeIsPractice && this.unconfirmedRecordingAudioID) {
        await this.$axios
          .post(
            `/tk/front/tongue/answer/${this.questionlist[this.activeIndex].ID}/uploadcancel?key_success=${
              this.key_success
            }`
          )
          .then(() => {
            this.questionlist[this.activeIndex].q_res = ''
          })
          .catch(() => {
            this.$Toast.clear()
          })
        this.unconfirmedRecordingAudioID = ''
      }
      return new Promise((resolve) => {
        this.$axios
          .post('/tk/front/tongue/answer/upload', formData)
          .then(async ({ data }) => {
            await this.saveTongueAudioList(data.data)
            // 清空
            this.pcmAudioFile = null
            this.pcmAudioGrade = {}
          })
          .catch(() => {
            this.$Toast.clear()
          })
        resolve(1)
      })
    },
    // 判断文本是否溢出隐藏
    isReadTextOverflowEllipsis() {
      this.showMoreBtn = false
      if (!this.pageTypeIsPractice || !this.questionlist.length) return
      this.$nextTick(() => {
        const elReadText = this.$refs['read-text' + this.questionlist[this.activeIndex].ID][0]
        var lineHeight = getComputedStyle(elReadText).lineHeight.split('px')[0]
        var offsetHeight = elReadText.offsetHeight
        this.showMoreBtn = offsetHeight >= lineHeight * 3 ? true : false
        // 客户要求初始就展开
        this.tongueStatus.textExpended = this.showMoreBtn
      })
    },
    formatValue(value) {
      let num = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      return num[value] || '一'
    },
    formatMMss(hhmmss) {
      const splitTime = hhmmss.split(':')
      const secs = Number(splitTime[0]) * 60 * 60 + Number(splitTime[1]) * 60 + Number(splitTime[2])
      return secs * 1000
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    // 查看答题情况
    golist() {
      if (!this.checkAudioCount()) return
      this.isShow = true
    },
    // 交卷
    getover(isShowDiag) {
      if (!this.checkAudioCount()) return
      this.loading = true
      let key_success = this.key_success
      if (!key_success) {
        return
      }
      let res = []
      let flag = true
      for (let i = 0; i < this.questionlist.length; i++) {
        let item = this.questionlist[i]
        let obj = {}
        let key = item.parentID ? 'options_O' + item.ID : 'options' + item.ID
        obj['name'] = key
        if (item.type.indexOf('Completion') > -1 || item.type.indexOf('Answer') > -1) {
          let r = ''
          for (let j = 0; j < item.inputOptions.length; j++) {
            if (item.inputOptions[j].value) {
              r = r + item.inputOptions[j].value + (j < item.inputOptions.length - 1 ? '_ZVING_' : '')
            }
          }
          obj['value'] = r
          if (!r) {
            flag = false
          } else {
            flag = true
          }
        } else if (item.type.indexOf('MultipleChoice') > -1 || item.type.indexOf('CaseAnalysis') > -1) {
          obj['value'] = item.q_res.sort().join('')
          if (!obj['value']) {
            flag = false
          } else {
            flag = true
          }
        } else if (item.type.indexOf('Tongue') > -1) {
          if (this.pageTypeIsPractice) {
            if (Array.isArray(item.q_res)) {
              obj['value'] = item.q_res.join(',')
            } else {
              obj['value'] = ''
            }
          } else {
            obj['value'] = item.q_res
          }
          if (obj['value'] === '' || obj['value'] === null) {
            // 可能为0
            flag = false
          } else {
            flag = true
          }
        } else {
          obj['value'] = item.q_res
          if (!obj['value']) {
            flag = false
          } else {
            flag = true
          }
        }
        flag && res.push(obj)
      }

      if (isShowDiag) {
        Dialog.confirm({
          title: '',
          message: `${res.length === this.questionlist.length ? '您确定交卷吗？' : '您还有题目未答，是否提交试卷？'}`,
        })
          .then(() => {
            let fd = new FormData()
            fd.append('formData', JSON.stringify(res))
            fd.append('elapsedTime', this.totalTime - this.remainingTime)
            this.$axios
              .post(`/tk/front/paper/save?key_success=${key_success}&platform=h5`, fd)
              .then((res) => {
                if (res.data.status === 1) {
                  this.saveLoading = true
                  localStorage['answer_activeIndex' + this.answerID] = '-1'
                  this.loading = false
                  if (this.photoCount > 0 && this.isPhoto && this.paperType === '25' && this.photoVideoOk) {
                    this.takePhoto()
                  }
                  this.$Toast.success(res.data.message)
                  localStorage['answer_' + this.examPaperId] = ''
                  setTimeout(() => {
                    if (this.pageTypeIsPractice) {
                      if (this.$route.query.errorAgain) {
                        // 前往错题重做结果页面
                        this.$router.replace({
                          path: `/allCourses/errorAgain/${this.paperID}`,
                        })
                      }
                      if (!this.examPaperId) {
                        return
                      }
                      // 前往答题结果页面
                      this.$router.replace({
                        path: `/study/studyreport/${this.paperID}/${this.examPaperId}`,
                        query: {
                          paperType: this.paperType,
                          answerID: this.answerID,
                        },
                      })
                    } else if (this.pageTypeIsErrorCreated) {
                      // 前往错题列表页面
                      this.$router.replace({
                        path: `/allCourses/allErrorQuestion`,
                      })
                    } else {
                      if (!this.examPaperId) {
                        return
                      }
                      this.$router.replace({
                        path: `/study/testresults/${this.paperID}/${this.examPaperId}`,
                        query: {
                          paperType: this.paperType,
                        },
                      })
                    }
                  }, 1000)
                } else {
                  this.$Toast.fail(res.data.message)
                }
              })
              .catch((e) => {
                this.$Toast.fail('服务器开小差了，稍后重试')
                return e
              })
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        let fd = new FormData()
        fd.append('formData', JSON.stringify(res))
        fd.append('elapsedTime', this.totalTime - this.remainingTime)
        this.$axios
          .post(`/tk/front/paper/save?key_success=${key_success}&platform=h5`, fd)
          .then((res) => {
            if (res.data.status === 1) {
              localStorage['answer_activeIndex' + this.answerID] = '-1'
              this.loading = false
              if (this.photoCount > 0 && this.isPhoto && this.paperType === '25' && this.photoVideoOk) {
                this.takePhoto()
              }
              this.$Toast.success(res.data.message)
              localStorage['answer_' + this.examPaperId] = ''
              setTimeout(() => {
                if (this.pageTypeIsPractice) {
                  if (this.$route.query.errorAgain) {
                    // 前往错题重做结果页面
                    this.$router.replace({
                      path: `/allCourses/errorAgain/${this.paperID}`,
                    })
                  }
                  if (!this.examPaperId) {
                    return
                  }
                  // 前往答题结果页面
                  this.$router.replace({
                    path: `/study/studyreport/${this.paperID}/${this.examPaperId}`,
                    query: {
                      paperType: this.paperType,
                      answerID: this.answerID,
                    },
                  })
                } else if (this.pageTypeIsErrorCreated) {
                  // 前往错题列表页面
                  this.$router.replace({
                    path: `/allCourses/allErrorQuestion`,
                  })
                } else {
                  if (!this.examPaperId) {
                    return
                  }
                  this.$router.replace({
                    path: `/study/testresults/${this.paperID}/${this.examPaperId}`,
                    query: {
                      paperType: this.paperType,
                    },
                  })
                }
              }, 1000)
            } else {
              this.$Toast.fail(res.data.message)
            }
          })
          .catch((e) => {
            this.$Toast.fail('服务器开小差了，稍后重试')
            return e
          })
      }
    },
    // 上一题
    preQues() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
        if (this.count > 0) {
          this.count = this.count - 1
        }
      } else {
        this.$Toast.fail('已经是第一题了')
      }
    },
    // 下一题
    nextQues() {
      if (!this.checkAudioCount()) return
      if (this.activeIndex < this.questionlist.length - 1) {
        this.activeIndex = this.activeIndex + 1
        this.count = this.count + 1
        if (this.$route.query.errorAgain) {
          return
        }
        if (this.count === 5) {
          this.save(true)
        }
        // 本地保存一次数据
        this.save(true, true)
      } else {
        this.$Toast.fail('已经是最后一题了')
      }
    },
    // 题目相关
    async getData() {
      let key_success = this.key_success
      // 答题界面初始化
      await this.$axios
        .get(`/tk/front/paper/answerpage?key_success=${key_success}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.paperType = res.data.data.paperType
            this.complexity = res.data.data.complexity
            this.paperID = res.data.data.paperID
            this.answerID = res.data.data.answerID
            this.isPhoto = res.data.data.isPhoto
            this.remainingTime = res.data.data.remainingTime || 0
            this.totalTime = res.data.data.time * 60
            this.userAnswer = res.data.data.userAnswer ? JSON.parse(res.data.data.userAnswer) : []
            this.photoCount = res.data.data.photoCount
            this.canClosePage = res.data.data.isCloseWindow
            this.isAllowViewParsing = res.data.data.isViewParsing
            this.isPracticeSelfTest = res.data.data.isSelfTest !== 0
            this.tonguePracticeNum = this.isPracticeSelfTest ? 1 : res.data.data.tonguePracticeNum || 3 // 默认为3
            if ((this.pageTypeIsOfficialExam || this.pageTypeIsSimulativeExam) && !this.pageTypeIsPractice) {
              let message = '超过考试时间会自动交卷'
              if (!this.canClosePage) {
                message = '退出页面或超过考试时间会自动交卷'
                if (res.data.data.answerCount > 1) {
                  this.getover(false)
                }
              }
              Dialog.confirm({
                message: message,
                showCancelButton: false,
              })
                .then(async () => {
                  if (this.pageTypeIsOfficialExam && res.data.data.isPhoto && this.photoCount > 0) {
                    await this.getCameraAction()
                    this.photoInterval()
                  }
                })
                .catch(async () => {
                  if (this.pageTypeIsOfficialExam && res.data.data.isPhoto && this.photoCount > 0) {
                    await this.getCameraAction()
                    this.photoInterval()
                  }
                })
              this.overTimer = setInterval(() => {
                this.remainingTime--
                if (this.remainingTime <= 0) {
                  this.remainingTime = 0 // 剩余时间初始化页面为0时，会有-1的情况
                  // 交卷
                  this.getover(false)
                  clearInterval(this.overTimer)
                  this.overTimer = null
                }
                this.formatDuring()
              }, 1000)
            }

            // 答题界面试题列表接口
            this.$axios
              .get(`/tk/front/paper/questionlist?key_success=${key_success}`)
              .then((res) => {
                if (res.data.status === 1) {
                  let data = res.data.data
                  if (Array.isArray(data) && data.length <= 0) {
                    this.$Toast.fail('暂无试题')
                    return
                  }
                  data = data.map((item) => {
                    if (item.type.indexOf('Completion') > -1) {
                      let arr = []
                      for (let i = 0; i < item.options; i++) {
                        let obj = {
                          value: '',
                        }
                        arr.push(obj)
                      }
                      item.inputOptions = arr
                    } else if (item.type.indexOf('Answer') > -1) {
                      let arr = []
                      let obj = {
                        value: '',
                      }
                      arr.push(obj)
                      item.inputOptions = arr
                    } else if (item.type.indexOf('MultipleChoice') > -1 || item.type.indexOf('CaseAnalysis') > -1) {
                      item.q_res = []
                    } else if (item.type === 'Checking') {
                      item.options = 'A.Y.正确_ZVING_B.N.错误'
                    } else {
                      item.q_res = ''
                    }
                    return item
                  })
                  this.questionlist = data
                  // init
                  if (this.isTongueType) {
                    this.tongueMediaSrcData.audioUrl = this.questionlist[this.activeIndex].audioUrl
                    this.tongueMediaSrcData.videoAssertID = this.questionlist[this.activeIndex].videoAssertID
                  }
                  if (this.pageTypeIsPractice) this.getTongueAudioList(this.questionlist[this.activeIndex].ID)
                  else {
                    this.getTongueExamAudioList(this.questionlist[this.activeIndex].ID)
                  }
                  this.initAnswerpage()
                } else {
                  this.$Toast.fail(res.data.message)
                }
              })
              .catch((e) => {
                console.log(e)
                this.$Toast.fail('服务器开小差了，稍后重试')
                return e
              })
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
      // 试卷中所有题型
      this.$axios
        .get(`/tk/front/paper/questiontypelist?key_success=${key_success}`)
        .then((res) => {
          if (res.data.status !== 1) {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 倒计时
    formatDuring() {
      let mss = this.remainingTime * 1000
      let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = (mss % (1000 * 60)) / 1000
      let str = hours + ' 小时 ' + minutes + ' 分钟 ' + seconds + ' 秒 '
      return str
    },
    // 保存并退出
    save(isNotShowMsg, isSaveLocal, notJump) {
      let res = []
      for (let i = 0; i < this.questionlist.length; i++) {
        let item = this.questionlist[i]
        let obj = {}
        let flag = true
        obj['questionID'] = item.ID
        if (item.type.indexOf('Completion') > -1 || item.type.indexOf('Answer') > -1) {
          let r = ''
          for (let j = 0; j < item.inputOptions.length; j++) {
            if (item.inputOptions[j].value) {
              r = r + item.inputOptions[j].value + (j < item.inputOptions.length - 1 ? '_ZVING_' : '')
            }
          }
          obj['answer'] = r
          if (!r) {
            flag = false
          }
        } else if (item.type.indexOf('MultipleChoice') > -1 || item.type.indexOf('CaseAnalysis') > -1) {
          obj['answer'] = item.q_res.sort().join('')
          if (!obj['answer']) {
            flag = false
          }
        } else if (item.type.indexOf('Tongue') > -1) {
          if (Array.isArray(item.q_res)) {
            obj['answer'] = item.q_res.join(',')
          } else {
            obj['answer'] = ''
          }
          if (obj['answer'] !== 0 && !obj['answer']) flag = false
          else flag = true
        } else {
          obj['answer'] = item.q_res
          if (!obj['answer']) {
            flag = false
          }
        }
        flag && res.push(obj)
      }
      localStorage['answer_activeIndex' + this.answerID] =
        localStorage['answer_activeIndex' + this.answerID] === '-1' ? 0 : this.activeIndex
      if (isSaveLocal) {
        localStorage['answer_' + this.examPaperId] = JSON.stringify(res)
        return
      }
      let key_success = this.key_success
      let id = this.examPaperId || ''
      let fd = new FormData()
      console.log(JSON.stringify(res))
      fd.append('answers', JSON.stringify(res))
      fd.append('elapsedTime', 0)
      this.$axios
        .post(`/tk/front/paper/add?key_success=${key_success}`, fd)
        .then((res) => {
          if (res.data.status === 1) {
            if (!isNotShowMsg && id) {
              if (this.handoutID) {
                this.$router.replace({
                  path: `/allCourses/courseHandout/${this.handoutID}`,
                })
                return
              }
              if (!notJump) {
                if (this.isQrCodeEntry) {
                  this.$router.replace('/')
                } else {
                  this.$router.back()
                }
              }
            } else {
              this.count = 0
            }
          } else {
            this.$Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          console.log(e)
          this.$Toast.fail('服务器开小差了，稍后重试5')
          return e
        })
    },
    setActiveIndex(index) {
      this.activeIndex = index
      this.isShow = false
    },
    initAnswerpage() {
      let localData = localStorage['answer_' + this.examPaperId]
        ? JSON.parse(localStorage['answer_' + this.examPaperId])
        : []
      let severData = this.userAnswer || []
      if (localData.length === 0 && severData.length === 0) {
        return
      }
      if (severData.length === 0 || severData.length < localData.length) {
        this.userAnswer = localData
      } else if (localData.length !== 0) {
        for (let i = 0; i < severData.length; i++) {
          let data = localData.find((item) => item.questionID === severData[i].QID)
          if (data && !severData[i].answer) {
            severData[i].answer = data.answer
          }
        }
      }
      for (let i = 0; i < this.questionlist.length; i++) {
        let item = this.questionlist[i]
        let answer = this.userAnswer.find((i) => i.questionID === item.ID || i.QID === item.ID)
        if (
          item.type.indexOf('MultipleChoice') === -1 &&
          item.type.indexOf('Completion') === -1 &&
          item.type.indexOf('CaseAnalysis') === -1 &&
          item.type.indexOf('Tongue') === -1 &&
          item.type.indexOf('Answer') === -1
        ) {
          item.q_res = answer ? answer.answer : ''
        } else if (item.type.indexOf('Completion') > -1 || item.type.indexOf('Answer') > -1) {
          let arr = answer ? (answer.answer ? answer.answer.split('_ZVING_') : []) : []
          for (let j = 0; j < item.inputOptions.length; j++) {
            item.inputOptions[j].value = arr[j] ? arr[j] : ''
          }
        } else if (item.type.indexOf('Tongue') > -1) {
          if (answer && answer.answer) {
            if (this.pageTypeIsPractice) {
              item.q_res = answer.answer.split(',')
            } else {
              item.q_res = answer.answer
            }
          }
        } else {
          item.q_res = answer ? (answer.answer ? answer.answer.split('') : []) : []
        }
      }
    },
    confirm() {},
    // 剩余答题时间前1/3的时间需要拍照（剩余拍照次数的1/2 + 1 次）,如果还有剩余拍照次数的话，交卷需要拍照一次
    photoInterval() {
      if (this.photoVideoOk) {
        const threePTime = parseInt(this.remainingTime / 3)
        const threePPhoto = parseInt(this.photoCount / 2) + 1
        const num = parseInt(threePTime / threePPhoto)
        const randomNum = Math.random() * num
        setTimeout(() => {
          this.takePhoto()
        }, randomNum * 1000)
      }
    },
    takePhoto() {
      let canvas = document.getElementById('canvas')
      let video = document.getElementById('video')
      canvas.getContext('2d').drawImage(video, 0, 0, 320, 380)
      this.userImage = canvas.toDataURL('image/png')
      this.uploadImg()
    },
    uploadImg() {
      let params = new FormData()
      let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      params.append('key_success', this.key_success)
      params.append('imgData', this.userImage)
      if (!this.userImage) {
        return
      }
      this.$axios.post('/tk/front/paperphoto/upload', params, { headers: headers }).then((res) => {
        if (res.data.status === 1) {
          this.photoCount--
          if (this.photoCount > 0) {
            this.photoInterval()
          }
        } else {
          this.$Toast.fail(res.data.message)
        }
      })
    },
    getCameraAction() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      const constraints = {
        video: true,
        audio: false,
      }
      const that = this
      let v = document.getElementById('video')
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      return new Promise((resolve) => {
        promise
          .then((stream) => {
            let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            // 旧的浏览器可能没有srcObject
            if ('srcObject' in v) {
              v.srcObject = stream
            } else {
              // 防止再新的浏览器里使用它，应为它已经不再支持了
              v.src = window.URL.createObjectURL(stream)
            }
            v.onloadedmetadata = function () {
              that.photoVideoOk = true
              v.play()
              return resolve(true)
            }
            if (isiOS) {
              return
            }
            that.photoPreviewShow = true
            this.$nextTick(() => {
              let photoPreview = document.getElementById('photoPreview')
              if ('srcObject' in photoPreview) {
                photoPreview.srcObject = stream
              } else {
                // 防止再新的浏览器里使用它，应为它已经不再支持了
                photoPreview.src = window.URL.createObjectURL(stream)
              }
              photoPreview.onloadedmetadata = function () {
                that.photoVideoOk = true
                photoPreview.play()
                return resolve(true)
              }
            })
          })
          .catch((err) => {
            if (String(err).indexOf('getUserMedia is not implemented') !== -1) {
              that.photoVideoOk = false
              this.$Toast.fail('手机系统版本过低，无法正常使用拍照功能')
            } else {
              that.photoVideoOk = false
              this.$Toast.fail('未开启摄像头，不能正常使用拍照功能，无法继续作答')
            }
            resolve(true)
            return err
          })
      })
    },
    goBack() {
      if (this.canClosePage) {
        this.save(true)
      } else {
        this.getover(false)
      }
    },
    highlightOutsideHashes(str = '', isClear) {
      str = str.replaceAll('\n', '<br/>')
      // 正则表达式匹配 #...# 之间的内容，包括单个 #，以及它们之间的所有内容
      let matches = []
      let result = str.replace(/#[^#]*#/g, (match) => {
        // 将匹配到的 #...# 内容保存起来，稍后替换回去
        const placeholder = `__HASH_${matches.length}__`
        matches.push(match)
        return placeholder
      })

      // 使用正则表达式来匹配 __HASH_\d__ 模式，并捕获其间的任意字符（包括换行符）
      const pattern = /(__HASH_\d+__)|([\s\S]*?(?=__HASH_\d+__|$))/g
      result = result.replace(pattern, (match, p1, p2) => {
        if (p1) {
          // 如果是 __HASH_\d__，直接返回原字符串
          return p1
        } else if (p2) {
          // 否则，将非 __HASH_\d__ 内容用 <span> 标签包裹
          let placeholderText = `( )`
          if (p2.startsWith('<br/>') || p2.startsWith('<br>')) {
            placeholderText = '<br/>' + placeholderText
          }
          if (p2.endsWith('<br/>') || p2.endsWith('<br>')) {
            placeholderText += '<br/>'
          }
          return `<span class="green-text">${isClear ? placeholderText : p2}</span>`
        }
        return match // 默认情况下返回原始匹配项
      })

      // 将之前保存的 #...# 内容替换回原位，并去掉#
      matches.forEach((match, index) => {
        const placeholder = `__HASH_${index}__`
        result = result.replace(placeholder, match.replaceAll('#', ''))
      })
      // html无法转义\n 需替换成br标签
      return result
    },
    handleTalkAnswer(answer = '') {
      if (Number(this.paperType) !== 1) {
        return answer
      }
      // 只有自测模式录音中才隐藏正确答案
      if (this.isPracticeSelfTest && this.isRecording) {
        return this.highlightOutsideHashes(answer, true)
      } else {
        return this.highlightOutsideHashes(answer)
      }
    },
    clearOutsideHashes(str = '') {
      // 正则表达式匹配 #...# 之间的内容，并去除，得到标准答案
      let result = str.replace(/#[^#]*#|\s|\u3000/g, '')
      return result
    },
  },
  destroyed() {
    this.goBack()
    Dialog.close()
    this.initCurrentAudio()
    clearInterval(this.overTimer)
    this.overTimer = null
  },
}
</script>
<style scoped>
.examinationpaper >>> .groupOption.van-radio-group {
  display: flex;
  justify-content: space-around;
}
.examinationpaper >>> .van-nav-bar__title {
  font-size: 0.45rem !important;
}
.examinationpaper >>> .van-nav-bar {
  line-height: 0.58rem !important;
}
.examinationpaper >>> .van-radio__icon {
  font-size: 0.53rem;
}
.examinationpaper >>> .van-radio__label,
.van-checkbox__label {
  margin-left: 0.21rem;
  line-height: 0.53rem;
}
.examinationpaper >>> img {
  max-width: 100%;
  height: auto;
}
.examinationpaper >>> audio {
  width: calc(100vw - 1.86rem);
  height: 1.44rem;
}
.examinationpaper >>> .van-checkbox__icon {
  font-size: 0.53rem;
}
.testbox .options >>> .tkt.van-cell::after {
  right: 0;
  left: 0;
}
.testbox .options >>> .tkt {
  margin-bottom: 0.2133rem;
}
.testbox .options >>> .jdt.van-cell::after {
  right: 0;
  left: 0;
}
.examinationpaper >>> .van-popup {
  box-shadow: 0px -3px 50px 0px rgba(172, 172, 172, 0.35);
}
</style>
<style lang="less" scoped>
.examinationpaper {
  background: #fff;
  &--isTongueType {
    background: #f8f8f8;
    .operate-btn-group,
    .btn-group {
      padding-bottom: 0.1rem !important;
    }
    .read-text {
      margin-bottom: 0 !important;
      padding-bottom: 0.5rem !important;
    }
    .paperbox--colloquial {
      height: calc(100vh - 1.33rem - 3.2rem - 1.22rem) !important;
    }
    .popup__wrap {
      padding: 0.1rem 0 !important;
    }
    .recording-countdown {
      margin-top: 0 !important;
    }
    .btn-text {
      margin: 0rem !important;
    }
    .main-btn-bottom {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
    .rotate-playing-audio-btn,
    .sup-btn img {
      width: 1rem !important;
    }
    .btn-group button {
      height: 1rem !important;
    }
  }
  .disabled-click {
    pointer-events: none;
  }
  .hide-placeholder {
    visibility: hidden;
  }
  .popup__wrap {
    padding: 1.04rem 0;
    text-align: center;
    font-size: 0.3733rem;
    .btn-text {
      margin-top: 0.3333rem;
      margin-bottom: 0.1333rem;
      color: #fe714b;
    }
    .audio-wave {
      width: 100%;
    }
    .recording-countdown {
      margin-top: 0.7333rem;
      margin-bottom: 0.3rem;
    }
  }
  .video-box--unclickable {
    pointer-events: none;
  }
  .paperbox {
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 1.33rem - 5rem - 1.22rem);
    overflow-y: auto;
    // min-height: 80vh;
    // min-height: calc(100vh - 3.2rem);
  }
  .paperbox--colloquial {
    height: calc(100vh - 1.33rem - 5rem - 1.22rem);
  }
  // .paperbox--expanded {
  // }
  .paperbox--tongue {
    background: #f8f8f8;
  }
  .paperbox--tongue-exam {
    background: #fff;
  }
  .paperbox--scroll {
    .read-text {
      min-height: calc(100vh - 1.22rem - 1.33rem - 5rem - 2.24rem);
      // height: calc(100vh - 2.24rem - 1.33rem - 5.6491rem - 1.22rem);
    }
  }
  .tongue-box {
    // overflow-y: auto;
    &--isTongueType {
      background: #f8f8f8;
    }
    .tongue-topic {
      .title__wrap {
        padding: 0.27rem 0.33rem;
      }
      .title__wrap--single-line {
        padding: 0.27rem 0.33rem 0.82rem 0.33rem;
      }
    }

    .current-score {
      margin-top: 0.24rem;
      font-size: 14px;
      text-align: left;
      color: #8e8e8e;
      font-weight: 500;
      img {
        max-width: 26px;
        margin-right: 0.0533rem;
        vertical-align: sub;
      }
      .passed {
        color: #fe714b;
      }
    }
    .spoken-title {
      .title_type {
        font-size: 0.28rem;
        padding: 0.11rem 0.2rem;
        color: #fff;
        background-image: linear-gradient(90deg, #ffbd9a 0%, #fe714b 100%);
        border-radius: 0.27rem 0rem 0.27rem 0rem;
        margin-right: 0.2rem;
      }
      .title_text {
        font-family: PingFang-SC-Medium;
        font-size: 0.43rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.65rem;
        letter-spacing: 0rem;
        color: #333333;
        /deep/ p {
          display: inline;
        }
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .operate-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pass-condition {
      padding: 0.0933rem;
      margin-top: 0.2533rem;
      background: #fef9f6;
      font-family: PingFang-SC-Medium;
      font-size: 0.38rem;
      color: #fe906c;
    }
    .read-text {
      padding: 0.3rem 0.4rem 1rem;
      background: #fff;
      box-sizing: border-box;
      font-family: PingFang-SC-Medium;
      font-size: 0.42rem;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.04);
      .title {
        margin-bottom: 0.2533rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.38rem;
        color: #1578f2;
      }
      .text {
        height: 100%;
        line-height: 0.7467rem;
        word-break: break-all;
      }
      .text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //行数
        -webkit-box-orient: vertical;
      }
      .more-btn {
        font-size: 0.4267rem;
        color: #1578f2;
      }
    }
  }
  .tongue-box--recording {
    height: calc(100vh - 1.33rem);
    // overflow-y: auto;
  }
  .tongue-box--recording--video {
    height: calc(100vh - 5.65rem - 1.33rem);
    // overflow-y: auto;
  }
  .tongue-box--video {
    height: calc(100vh - 5.65rem - 2.55rem - 5.6491rem);
    // overflow-y: auto;
  }
  .tongue-box--normal {
    height: calc(100vh - 2.55rem - 5.6491rem);
    // overflow-y: auto;
  }
  .tongue-box--expended {
    height: calc(100vh - 2.55rem - 5.6491rem);
    background: #f8f8f8;
    // overflow-y: auto;
  }
  .tongue-box--expended--video {
    // height: calc(100vh - 25vh - 2.55rem - 5.6491rem);
    background: #f8f8f8;
    // overflow-y: auto;
  }
  .testbox {
    padding: 0.13rem 0;
    padding-bottom: 3rem;
    // max-height: calc(100vh - 5.33rem);
    // max-height: calc(100vh - 4rem);
    overflow: auto;
    &--openViewParsing {
      padding-bottom: 0.13rem !important;
    }
    .title {
      margin-bottom: 0.28rem;
      .title_type {
        font-size: 0.28rem;
        padding: 0.11rem 0.2rem;
        color: #fff;
        background-image: linear-gradient(90deg, #53a0ff 0%, #1578f2 100%);
        border-radius: 0.27rem 0rem 0.27rem 0rem;
        margin-right: 0.2rem;
      }
      .title_text {
        font-family: PingFang-SC-Medium;
        font-size: 0.43rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.65rem;
        letter-spacing: 0rem;
        color: #333333;
        /deep/ p {
          display: inline;
        }
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .options {
      .van-radio,
      .van-checkbox {
        margin-bottom: 0.66rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
      }
      /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;
      }
      .van-cell--borderless::after,
      .van-cell:last-child::after {
        display: block;
      }
    }
    .title_p_title {
      font-family: PingFang-SC-Medium;
      font-size: 0.43rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.65rem;
      letter-spacing: 0rem;
      color: #333333;
      margin-bottom: 0.28rem;
      /deep/ p {
        display: inline-block;
        width: 100%;
      }
    }
  }
  .operate-btn-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.2667rem 0.9333rem 0.48rem 0.9333rem;
    box-sizing: border-box;
    font-size: 0.3733rem;
    color: #fe714b;
    .loading-teach-audio {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .sup-btn,
    .main-btn {
      text-align: center;
    }
    .icon-recording {
      width: 0.6533rem;
      height: 0.92rem;
      background: url('../../../public/images/allCourse/paper/record-btn-icon.png') no-repeat center center;
      background-size: contain;
    }
    .template-btn--disabled {
      filter: saturate(0%);
    }
    .main-btn-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 2.1333rem;
      height: 2.1333rem;
      background: url('../../../public/images/allCourse/paper/record-btn-bottom.png') no-repeat;
      background-size: contain;
    }
    .main-btn-count {
      margin-top: 0.1467rem;
      font-size: 0.3733rem;
      color: #fff;
    }
    .rotate-playing-audio-btn {
      animation: rotate-playing 10s linear infinite;
    }
    .animation-paused {
      animation-play-state: paused;
    }

    @keyframes rotate-playing {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .change-btn--tongue-practice {
    background-color: #f8f8f8 !important;
  }
  .changebtn {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: auto;
    position: fixed;
    width: 100%;
    bottom: 1.33rem;
    padding: 1px 0;
    background-color: #fff;
    .info-btn {
      width: 30%;
      height: 1.17rem;
      .van-button__text {
        font-size: 0.4rem;
      }
    }
    .btn-group {
      display: flex;
      justify-content: space-around;
      padding-bottom: 0.4533rem;
    }
  }
  .changebtn--solid {
    background-color: #f8f8f8;
  }
  .boxbotm {
    border-top: 1px solid #f2f2f2;
    display: flex;
    position: fixed;
    bottom: 0;
    height: 1.33rem;
    line-height: 1rem;
    width: 100%;
    padding: 0 0.33rem;
    font-family: PingFang-SC-Medium;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    background-color: #fff;
    box-sizing: border-box;
    .botmicon {
      font-size: 0.5rem;
      vertical-align: sub;
      margin-right: 0.25rem;
    }
    .time {
      text-align: center;
      display: inline-block;
      margin-left: 1rem;
    }
  }
  // 口语音频列表
  .popup-audio-list__wrap {
    height: 72vh;
    padding: 0.4267rem 0;
    .list-scroll__wrap {
      height: 58vh;
      padding: 0 0.4rem;
      overflow-y: auto;
    }
    .close-btn__wrap {
      margin-top: 8vh;
      text-align: center;
      .close-btn {
        font-size: 0.4267rem;
        color: #808080;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 0 0.4267rem;
      margin-bottom: 0.4267rem;
      font-weight: 500;
      font-size: 0.4267rem;
      color: #0e0e0e;
    }
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2667rem 0;
      .icon {
        width: 0.6933rem;
        height: 0.5333rem;
        margin-right: 0.2133rem;
        vertical-align: middle;
      }
      .name {
        width: 47vw;
        margin-right: 0.3333rem;
        font-weight: 500;
        font-size: 0.4rem;
        line-height: 0.4rem;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        font-size: 0.32rem;
        color: #a2a2a2;
      }
      .score {
        font-size: 0.4rem;
        color: #66cb55;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 0.4rem;
      }
    }
    .list-item--playing {
      .name {
        color: #fe714b !important;
      }
    }
    .list-item--failed {
      // .icon {
      //   filter: saturate(0%);
      // }
      .name {
        color: #a6a6a6;
      }
      .time {
        color: #c3c3c3;
      }
      .score {
        color: #cecece;
      }
    }
  }
  .confirm-dialog__wrap {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    .confirm-dialog {
      position: fixed;
      top: 23vh;
      left: 50%;
      transform: translateX(-50%);
      width: 7.8133rem;
      height: 6.5547rem;
      padding: 2.28rem 0.48rem 0.4667rem 0.48rem;
      background: url('../../../public/images/allCourse/paper/dialog1.png') no-repeat;
      background-size: contain;
      box-sizing: border-box;
      text-align: center;
    }
    .confirm-operate-btn-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .title {
      margin-bottom: 0.5467rem;
      font-weight: 700;
      font-size: 0.48rem;
      color: #0e0e0e;
    }
    .text {
      margin-bottom: 0.5467rem;
      font-weight: 500;
      font-size: 0.3467rem;
      color: #5b5b5b;
      text-align: left;
    }
    .btn {
      padding: 0.36rem 1.2rem;
      border-radius: 0.16rem;
      border-color: transparent;
      font-weight: 500;
      font-size: 0.4rem;
      white-space: nowrap;
    }
    .btn-cancel {
      background-color: #f6f6f6;
      color: #5b5b5b;
    }
    .btn-highlighted {
      background-color: #fe714b;
      color: #fff;
    }
  }
}

.analysis {
  border-top: 0.29rem solid #f8f8f8;
  padding: 0.1rem;
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  &--isTongueType {
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin: 0rem 0.23rem;
  }
  .analysisbox {
    text-align: center;
    padding: 0.54rem 0;
    .van-col {
      position: relative;
    }
    .van-col::after {
      content: ' ';
      position: absolute;
      top: 25%;
      right: 0;
      width: 1px;
      height: 0.8rem;
      background-color: #e5e5e5;
    }
    .van-col:last-child::after {
      content: '';
      width: 0;
    }
    .analysisnum {
      font-size: 0.27rem;
      font-family: PingFang-SC-Heavy;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #111111;
      margin-bottom: 0.3rem;
      span {
        font-size: 0.45rem;
      }
    }
    .analysistext {
      font-family: PingFang-SC-Regular;
      font-size: 0.27rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #333333;
    }
  }
  .analysisbtom {
    .btomtext {
      font-family: PingFang-SC-Medium;
      font-size: 0.35rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      box-sizing: border-box;
      padding: 0.1rem 0.3rem;
      color: #444444;
      padding-bottom: 3.33rem;
      /deep/ img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.correct {
  .line {
    display: inline-block;
    width: 0.18rem;
    height: 0.43rem;
    background-color: #1578f2;
    border-radius: 0.08rem;
    vertical-align: middle;
    margin-right: 0.29rem;
  }
  .correcttext {
    font-family: PingFang-SC-Medium;
    font-size: 0.4rem;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #222222;
  }
}
</style>
<style>
.examinationpaper .rate-list li {
  line-height: 22px !important;
}
.green-text {
  background: #00ff00;
}
</style>
